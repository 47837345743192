import React from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { MaxWidth } from '../components/maxWidth';

const NotFoundPage = () => (
    <>
        <Seo title="404: Not found" />
        <MaxWidth padded>
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist.</p>
        </MaxWidth>
    </>
);

export default NotFoundPage;
